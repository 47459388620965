import React from 'react'

import styles from './Jobs.less'

import Page from '../Page'

export default () => {
  return (
    <Page title='Jobs' ssr={true}>
      <div className={styles.Buffer} />
      <div id='ashby_embed' />
      <script src='https://jobs.ashbyhq.com/jobs-page-0a2deccf-d3c9-4357-814f-dc13864a1553/embed' />
    </Page>
  )
}
